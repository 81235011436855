/**
 * @generated SignedSource<<7c288b7d00d60fa83b91ace5b8d1aa13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentUsageSubmissionMethod = "mark_as_complete" | "submission" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentUsageAssignmentSubmitBannerFragment$data = {
  readonly id: string;
  readonly passPercentage: number | null;
  readonly submissionMethod: ContentUsageSubmissionMethod | null;
  readonly viewerSubmission: {
    readonly hasPassed: boolean | null;
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ContentUsageUtils_useSubmissionDeadlineFragment" | "SubmitAssignmentFormFragment">;
  readonly " $fragmentType": "ContentUsageAssignmentSubmitBannerFragment";
};
export type ContentUsageAssignmentSubmitBannerFragment$key = {
  readonly " $data"?: ContentUsageAssignmentSubmitBannerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentUsageAssignmentSubmitBannerFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentUsageAssignmentSubmitBannerFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submissionMethod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "passPercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentSubmission",
      "kind": "LinkedField",
      "name": "viewerSubmission",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasPassed",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentUsageUtils_useSubmissionDeadlineFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubmitAssignmentFormFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "fbaef0bb6c5c8e6b19ed68e28b13745f";

export default node;
